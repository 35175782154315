






































import { Vue, Component, Prop } from "vue-property-decorator";
import { CompanyInfoProperties } from "./CompanyInfoView.vue";
import CurrencyService from "@/services/CurrencyService";

@Component({
	data: () => ({
        currencies: [],
        loadingCurrencies: false,
	})
})
export default class CompanyMainView extends Vue {
	@Prop() private properties: CompanyInfoProperties;
    private currencyService = new CurrencyService();

	public get company() {
		return this.properties.company;
	}

	public created() {
		this.loadCurrencies();
	}

    public async loadCurrencies() {
        this.$data.loadingCurrencies = true;

        try {
            const r = await this.currencyService.get();
            const currencies = r.data.currencies;
            currencies.forEach(e => (e.text = `${e.code} - ${e.name}`));
            this.$data.currencies = currencies.sort((lhs, rhs) => rhs.id - lhs.id);
        } catch (e) {
            this.$data.currencies = [];
        } finally {
            this.$data.loadingCurrencies = false;
        }
    }
}
